import { iCarousel } from "./interfaces/carousel-plans";

const CarouselPlans: iCarousel[] = [
  {
    link: "",
    title: "Promoção - PLANO ESSENCIAL EXCLUSIVE",
    imgDesktop: "images/plans/exclusive_900_desk.png",
    imgMobile: "images/plans/exclusive_900_mobile.png",
    video: "",
    cities: [],
  },
  {
    link: "",
    title: "Promoção - PLANO ESSENCIAL PLUS",
    imgDesktop: "images/plans/essential_600_desk.png",
    imgMobile: "images/plans/essential_600_mobile.png",
    video: "",
    cities: [],
  },
  // {
  //   link: "",
  //   title: "Promoção Vídeo - Areado",
  //   imgDesktop: "",
  //   imgMobile: "",
  //   video: "https://youtu.be/LHxL00V9E_Q",
  //   cities: [{ city: "Areado", uf: "MG" }],
  // },
  // {
  //   link: "",
  //   title: "Promoção Vídeo - Machado",
  //   imgDesktop: "",
  //   imgMobile: "",
  //   video: "https://youtu.be/NVORdPrsKYc",
  //   cities: [{ city: "Machado", uf: "MG" }],
  // },
  // {
  //   link: "",
  //   title: "Promoção Vídeo - Belo Horizonte",
  //   imgDesktop: "",
  //   imgMobile: "",
  //   video: "https://youtu.be/XQJA40AdvhY",
  //   cities: [{ city: "Belo Horizonte", uf: "MG" }],
  // },
  // {
  //   link: "",
  //   title: "Promoção Vídeo - Inhaúma",
  //   imgDesktop: "",
  //   imgMobile: "",
  //   video: "https://youtu.be/HVjfND7hQWs",
  //   cities: [{ city: "Inhaúma", uf: "MG" }],
  // },
  // {
  //   link: "",
  //   title: "Promoção Vídeo - Paraguaçu",
  //   imgDesktop: "",
  //   imgMobile: "",
  //   video: "https://youtu.be/r6VrCCF_UEc",
  //   cities: [{ city: "Paraguaçu", uf: "MG" }],
  // },
  // {
  //   link: "",
  //   title: "Promoção Vídeo - Ribeirão Preto",
  //   imgDesktop: "",
  //   imgMobile: "",
  //   video: "https://youtu.be/_JuMk4L1iRg",
  //   cities: [{ city: "Ribeirão Preto", uf: "SP" }],
  // },
  // {
  //   link: "",
  //   title: "Promoção Vídeo - Sete Lagoas",
  //   imgDesktop: "",
  //   imgMobile: "",
  //   video: "https://youtu.be/MXecQw79_68",
  //   cities: [{ city: "Sete Lagoas", uf: "MG" }],
  // },
];
export default CarouselPlans;
